<template>
    <div class="bg-white box-shadow border-r px-20 pb-20">
        <div class="d-flex-center border-bottom">
            <h4 class="mr-auto color-333">{{ $t('account.invite_friends') }}</h4>
        </div>
        <div v-if="share.qrcode">
            <div class="input-form  mx-auto">
                <!-- <div class="d-flex-center pt-20">
                    <el-button v-for="(item, index) in info.networks" :key="index" type="primary" size='small' :plain="ni !== index" @click="ni = index">{{ item.label }}</el-button>
                </div> -->
                <div class="pb-20">
                    <div class="p-20 d-flex-center">
                        <el-image class="border border-r bg-white p-10" style="width: 200px; height: 200px;" :src="share.qrcode" />
                    </div>
                    <div class="font-18 text-center mb-20">{{ share.invitation_code }}</div>
                    <div class="text-center">
                        <el-button type="success" size='mini' icon="el-icon-document-copy" plain @click="onCopy(share.invitation_code)">Copy Invitation Code</el-button>
                    </div>
                    <div class="text-center mt-15">
                        <el-button type="primary" size='mini' icon="el-icon-document-copy" plain @click="onCopy(share.invitation_link)">Copy Invitation Link</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from '@/config.js'
import XEClipboard from 'xe-clipboard'
export default {
    data() {
        return {
            share: {
                qrcode: null,
                invitation_code: null,
                invitation_link: null
            }
        }
    },
    created: function() {
        this.fatchData()
    },
    methods: {
        fatchData: async function () {
            if(this.loading) return
            this.loading = true
            try{
                const { data } = await this.$request.get('team/invitation_web')
                data.invitation_link = config.originURL + data.invitation_link;
                data.qrcode = config.apiURL + 'qrcode?code=' + encodeURIComponent(data.invitation_link)
                this.share = data
            }catch(e){
                //TODO handle the exception
                // console.log(e)
            }
            this.loading = false
        },
        onCopy: function(text){
            if(XEClipboard.copy(text)){
                this.$message({
                    message: this.$t('copied'),
                    type: 'success'
                })
            }
        },
        // fatchRecord: async function () {
        //     this.loadingRecord = true
        //     try {
        //         const { data } = await this.$request.post('recharge/log_pc', this.query)
        //         this.list = data
        //     } catch (error) {
        //         //
        //     }
        //     this.loadingRecord = false
        // },
        // onSearch() {
        //     this.fatchRecord()
        //     this.$router.replace({
        //         query: this.query
        //     })
        // },
        // onPage(page) {
        //     this.query.page = page || 1
        //     this.onSearch()
        // }
    }
}
</script>
<style lang="scss" scoped>
.input-form{
    width: 480px;
}
.voucher-img{
    width: 360px;
    height: 270px;
}
</style>